<template>
  <div>
    <div class="card">
      <Toast position="top-center" group="info" />
      <div class="flex justify-content-between">
        <h5>Histories</h5>
        <Button
          label="Создать Histories"
          class="p-button-primary mb-4"
          @click="create()"
        />
      </div>
      <div v-if="items.length" class="my-company">
        <DataTable
          :value="items"
          responsiveLayout="scroll"
          paginator
          lazy
          :rows="itemsPerPage"
          :totalRecords="totalItems"
          @page="setPage"
        >
          <Column
            header="launchPad"
            field="launchPad.name"
            style="width: 100px"
          >
          </Column>
          <Column header="soldAmount" field="soldAmount" style="width: 100px">
          </Column>
          <Column header="receivedAmount" field="receivedAmount"></Column>
          <Column header="Продан" field="soldAt">
            <template #body="slot">
              {{
                slot.data.soldAt
                  ? moment(slot.data.soldAt).format('DD.MM.YYYY')
                  : 'нет данных'
              }}
            </template>
          </Column>
          <Column
            class="bg-white border-none flex flex-nowrap justify-content-end"
          >
            <template #body="slot">
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-warning m-1"
                @click="edit(slot.data)"
              />
              <Button
                icon="pi pi-trash"
                class="p-button-rounded p-button-danger m-1"
                @click="del(slot.data)"
              />
            </template>
          </Column>
        </DataTable>
      </div>
      <div v-else class="no-company">
        <p>Not any companies yet</p>
      </div>
    </div>
    <!-- Dialogs -->
    <Dialog
      v-model:visible="createDialog"
      :header="header"
      style="width: 700px"
      class="p-fluid"
      @hide="hide"
    >
      <div class="flex">
        <div class="flex-column col-6">
          <label>launchPad</label>
          <Dropdown
            v-model="selectedLaunchPad"
            :options="launchPad"
            optionLabel="name"
            placeholder="Select"
            class="mt-1 mb-3"
            @change="change"
          />
          <label>soldAmount</label>
          <InputText class="mt-1 mb-3" v-model="itemCreate.soldAmount" />
        </div>
        <div class="flex-column col-6">
          <label>receivedAmount</label>
          <InputText class="mt-1 mb-3" v-model="itemCreate.receivedAmount" />
          <label id="productname">Продан</label>
          <Calendar
            class="mt-1 mb-3"
            v-model="itemCreate.soldAt"
            showIcon
            dateFormat="dd.mm.yy"
            showTime
            hourFormat="24"
          />
        </div>
      </div>

      <div class="distance_button">
        <small class="p-error flex justify-content-center">
          <span v-if="!allField">Поля заполнены не все или не корректно</span>
          <span v-else>&nbsp;</span>
        </small>
        <Button
          :disabled="!allField"
          :label="button"
          class="p-button-primary mt-2"
          @click="editDialog ? editItem() : createItem()"
        />
      </div>
    </Dialog>

    <Dialog
      v-model:visible="deleteDialog"
      :style="{ width: '450px' }"
      header="Удаление"
      :modal="true"
    >
      <div class="confirmation-content">
        <p class="text-center p-error" v-if="itemCreate">
          Вы действительно хотите удалить Histories:
        </p>
        <p class="text-center">
          <b>{{ itemCreate.launchPad.name }} ?</b>
        </p>
      </div>
      <template #footer>
        <Button
          label="No"
          icon="pi pi-times"
          class="p-button-text"
          @click="deleteDialog = false"
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="removeItem"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  data() {
    return {
      items: [],
      launchPad: [],
      selectedLaunchPad: [],
      itemCreate: {
        launchPad: '',
        soldAmount: '',
        receivedAmount: '',
        soldAt: '',
      },
      createDialog: false,
      editDialog: false,
      deleteDialog: false,
      page: 1,
      itemsPerPage: 30,
      totalItems: 1,
    }
  },
  async mounted() {
    this.getLaunchPad()
    this.getItems()
  },
  methods: {
    moment(date) {
      return moment(date)
    },
    setPage(event) {
      this.page = event.page + 1
      this.getItems()
    },
    async getLaunchPad() {
      try {
        const resp = await this.axios.get('api/a_ut_launch_pads')
        this.launchPad = resp.data
      } catch (error) {
        console.log(error)
      }
    },
    async getItems() {
      const headers = {
        accept: 'application/ld+json',
        'Content-Type': 'application/ld+json',
      }
      const params = {
        page: this.page,
        itemsPerPage: this.itemsPerPage,
        pagination: true,
      }
      try {
        const resp = await this.axios.get('api/a_ut_sale_histories', {
          headers,
          params,
        })
        this.items = resp.data['hydra:member']
        this.totalItems = resp.data['hydra:totalItems']
      } catch (error) {
        console.log(error)
      }
    },

    hide() {
      this.itemCreate = {}
    },
    change(ev) {
      this.itemCreate.launchPad = `/api/a_ut_launch_pads/${ev.value.id}`
    },

    create() {
      this.selectedLaunchPad = ''
      this.itemCreate.soldAt = ''
      this.createDialog = true
    },
    edit(value) {
      this.itemCreate = { ...value }
      this.selectedLaunchPad = ''
      this.itemCreate.soldAt = ''
      this.editDialog = true
      this.createDialog = true
    },

    async createItem() {
      const params = this.itemCreate
      try {
        await this.axios.post('api/a_ut_sale_histories', params)
        this.$toast.add({
          severity: 'success',
          summary: 'Successful',
          detail: 'Histories created',
          group: 'info',
          life: 3000,
        })
        this.getLaunchPad()
        this.getItems()
        this.editDialog = false
        this.createDialog = false
      } catch (e) {
        this.editDialog = false
        this.createDialog = false
        console.log(e)
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Histories not created',
          group: 'info',
          life: 3000,
        })
      }
    },
    async editItem() {
      const headers = {
        accept: 'application/json',
        'Content-Type': 'application/merge-patch+json',
      }
      const id = this.itemCreate.id
      const params = this.itemCreate
      try {
        await this.axios.patch(`api/a_ut_sale_histories/${id}`, params, {
          headers: headers,
        })
        this.$toast.add({
          severity: 'success',
          summary: 'Successful',
          detail: 'Histories changed',
          group: 'info',
          life: 3000,
        })
        this.getLaunchPad()
        this.getItems()
        this.editDialog = false
        this.createDialog = false
      } catch (e) {
        this.editDialog = false
        this.createDialog = false
        console.log(e)
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Histories not changed',
          group: 'info',
          life: 3000,
        })
      }
    },

    del(value) {
      this.itemCreate = { ...value }
      this.deleteDialog = true
    },

    async removeItem() {
      const id = this.itemCreate.id
      try {
        await this.axios.delete(`api/a_ut_sale_histories/${id}`)
        this.$toast.add({
          severity: 'success',
          summary: 'Successful',
          detail: 'Histories deleted',
          group: 'info',
          life: 3000,
        })
        this.getLaunchPad()
        this.getItems()
        this.deleteDialog = false
      } catch (e) {
        this.deleteDialog = false
        console.log(e)
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Histories not delete',
          group: 'info',
          life: 3000,
        })
      }
    },
  },
  computed: {
    allField() {
      return (
        this.itemCreate.launchPad?.length &&
        this.itemCreate.soldAmount?.length &&
        this.itemCreate.receivedAmount?.length &&
        this.itemCreate.soldAt
      )
    },
    header() {
      return this.editDialog ? 'Редактировать Histories' : 'Создать Histories'
    },
    button() {
      return this.editDialog ? 'Редактировать' : 'Создать'
    },
  },
}
</script>

<style scoped>
.bange {
  color: white;
  padding: 3px 5px;
  border-radius: 5px;
}
</style>
